import React from "react";
import styled from "styled-components";
import { dateFormat } from "../../utils/dateFormat";
import { menuIcons } from "../../utils/icons";
import Button from "../Button/Button";

function IncomeItem({
    id,
    title,
    amount,
    date,
    category,
    subCategory,
    description,
    deleteItem,
    indicatorColor,
    type,
}) {
    const categoryIcon = () => {
        console.log(category);
        switch (category) {
            case "salary":
                return menuIcons.money;
            case "freelancing":
                return menuIcons.freelance;
            case "investments":
                return menuIcons.stocks;
            case "stocks":
                return menuIcons.users;
            case "bitcoin":
                return menuIcons.bitcoin;
            case "bank":
                return menuIcons.card;
            case "youtube":
                return menuIcons.yt;
            case "other":
                return menuIcons.piggy;
            default:
                return "";
        }
    };

    const expenseCatIcon = () => {
        switch (category) {
            case "education":
                return menuIcons.book;
            case "groceries":
                return menuIcons.food;
            case "health":
                return menuIcons.medical;
            case "subscriptions":
                return menuIcons.tv;
            case "takeaways":
                return menuIcons.takeaway;
            case "clothing":
                return menuIcons.clothing;
            case "travelling":
                return menuIcons.freelance;
            case "other":
                return menuIcons.circle;
            default:
                return menuIcons.circle;
        }
    };

    return (
        <IncomeItemStyled indicator={indicatorColor}>
            <div className="icon">
                {type === "expense" ? expenseCatIcon() : categoryIcon()}
            </div>
            <div className="content">
                <h5>{title}</h5>
                <div className="inner-content">
                    <div className="text">
                        <p>{category}</p>
                        <p>{subCategory}</p>
                    </div>
                </div>
                <div className="inner-content">
                    <div className="text">
                        <p>
                            {menuIcons.rupees} {amount}
                        </p>
                        <p>
                            {menuIcons.calender} {dateFormat(date)}
                        </p>
                        <p>
                            {menuIcons.comment}
                            {description}
                        </p>
                    </div>
                    <div className="btn-con">
                        <Button
                            icon={menuIcons.trash}
                            bPad={"1rem"}
                            bRad={"50%"}
                            bg={"var(--primary-color"}
                            color={"#fff"}
                            iColor={"#fff"}
                            hColor={"var(--color-green)"}
                            onClick={() => deleteItem(id)}
                        />
                    </div>
                </div>
            </div>
        </IncomeItemStyled>
    );
}

const IncomeItemStyled = styled.div`
    background: #fcf6f9;
    border: 2px solid #ffffff;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    color: #222260;
    .icon {
        width: 80px;
        height: 80px;
        border-radius: 20px;
        background: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #ffffff;
        i {
            font-size: 2.6rem;
        }
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        h5 {
            font-size: 1.3rem;
            padding-left: 2rem;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 50%;
                background: ${(props) => props.indicator};
            }
        }

        .inner-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .text {
                display: flex;
                align-items: center;
                gap: 1.5rem;
                p {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    color: var(--primary-color);
                    opacity: 0.8;
                }
            }
        }
    }
`;

export default IncomeItem;
